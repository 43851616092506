.topImage{
    position:relative;
    max-height: 172.07px !important;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
  }

  .imageFigure{
    -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
    position: relative;
    height: 172.07px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
  }
  .card:hover .imageFigure{
    -webkit-transform: scale(1.3);
      transform: scale(1.3);
  }
  .card:hover .imageFigure .screener{
    -webkit-transform: scale(1.2);
      transform: scale(1.2);
  }
  .provinceText{
    
    font-family: 'Roboto', sans-serif, medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.025em;
  
  color: #404040;
  margin-left: 6px;
  
  }
  .floatingLike{
    width: 28.06px;
    height: 26.84px;
    position: absolute;
    right:0px;
    top:10px;
    background: black;
    border-radius: 2px;
  }
  .floatingShare{
    width: 28.06px;
    height: 26.84px;
    position: absolute;
    right:0px;
    top:50px;
    background: black;
    border-radius: 2px;  
  }
  .tagLetter{
  padding-left: 5px;
  padding-right: 5px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
  }
  .apartmentTag{
    display: flex;
    position: absolute;
    left:15px;
    top:10px;
    justify-content: center;
    align-items: center;
    height: 26.84px;
    background: black;
    border-radius: 5px;
  }
  .profileButton{
    padding-left:15px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    height:43px;
    width: 43px;
    position: absolute;
    right:1rem;
    background: #FFFFFF;
    border: 0.1px solid #000000;
    border-radius: 50%;
  }
  .bulletLetter{
    padding:6px;
    width:180px ;
    margin: 0px !important;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }