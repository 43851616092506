/* Carly */
@font-face {
    font-family: "CambayBold";
    src: url(../media/Cambay-Bold.ttf)
    }
    @font-face {
        font-family: "CambayRegular";
        src: url(../media/Cambay-Regular.ttf)
        }
    @font-face {
        font-family: "RobottoRegular";
        src: url(../media/Roboto-Regular.ttf)
        }
        @font-face {
            font-family: "RobottoMedium";
            src: url(../media/Roboto-Medium.ttf)
            }
        @font-face {
            font-family: "RobottoLight";
            src: url(../media/Roboto-Light.ttf)
            }
            @font-face {
                font-family: "RobottoBold";
                src: url(../media/Roboto-Bold.ttf)
                }
.mainNavBarTitle{
font-family: "CambayBold";
font-size: 40px;
line-height: 34px;
color:inherit !important;
}
.white{
    color: #FFFFFF;
}
.black{
    color: #000000;
}
.labelCar{
    position: absolute;
    top:10px;
    left:10px;
    font-family: RobottoMedium;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: .8px;
    padding:10px;
    background-color: #000000;
    color:white;
    border-radius: 10px;
  }
@media (max-width:759px){
    .hugeBox{
        z-index: 4;
        min-width: 300px !important;
    }
    .hugeSearchMobile{
        width: 90% !important;
    }
    .secondMainNavTitle{
        
        text-align: center;
        font-size: 18px !important;
    }
}
@media (min-width:1124px){
    .mainTitle{font-size: 40px !important;
        min-width: 80vw !important;
        margin-bottom: 30px;
    }
    .secondMainNavTitle{
        max-width: 150px !important;
    }
}
@media (min-width:1345px){
    .background{
        background-size: 100% 100% !important;
        background-position-x: 20%;
      }
    .mainTitle{
        margin-bottom: 40px;
    }
    .secondMainTitle{
        margin-bottom: 20px;
    }
}
@media (max-width:759px){
    .secondMainTitle{
        font-size: 24px !important;
    }
}
@media (min-width:759px){
    .secondMainNavTitle{
        
        text-align: center;
        min-width: 200px !important;
    }
}
.mainTitle{
    
    width: auto;
    text-align: center;
    font-family: "CambayBold";
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    max-width: 70vw;
    color: #FFFFFF;
    mix-blend-mode: normal;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .robottoLight{
    font-family: RobottoLight;
    font-style: normal;
  }
  .secondMainTitle{
      /* Rectangle 45 */
/* Reserva el carro para tus proximas vacaciones */


font-family: "RobottoLight";
font-style: normal;
font-size: 30px;
line-height: 30px;
text-align: center;

color: #F7F7F7;


  }

.searchMainLabel{
/* Donde */
width: 87.1px;
height: 29px;

font-family: CambayBold;
font-size: 18px;
line-height: 18px;
position: relative;
top:7px;
color: #000000;


}
.searchDateLabel{
    /* Donde */
    width:40px;
    position:relative;
    top:10px;
    font-family: CambayBold;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
    
    
    }
    
.secondMainNavTitle{
    /* Publica tu carro */
    width: 20vw;
    text-align: center;
font-family: CambayBold;
font-style: normal;
font-weight: bold;
font-size: 24px;

}
.bulletPoint{
    /* Dashboard */
    display: flex;
justify-content: flex-end;

font-family: CambayBold;
font-size: 18px;
/* identical to box height */

color: #000000;


}
.dateField{
    font-family: RobottoRegular;
    font-weight: 500;
    font-size: 20px;
    width: 10vw;
    min-width: 107px;
    color: #525252;
    outline:0px !important;
    border:0px !important;
}
.categoryText{
    padding-top: 5px;
    font-family: CambayBold;
    font-size: 28px;
    width: auto;
    align-self: center;
    justify-content: center;
    align-content: center;
}
.priceTag{
    padding-top: 5px;
    letter-spacing: 1.2px;
    font-family: RobottoBold;
    font-size: 28px;
    width: auto;
    align-self: center;
    justify-content: center;
    align-content: center;
}
.robottoRegular{
    position:relative;
    font-family: RobottoRegular;
    font-weight: 500;
    font-size: 20px;
    line-height: 0px !important;
    color: #525252;}
#hugeInput::placeholder{
    width: 445px;
    
    font-family: RobottoRegular;
    font-weight: 500;
    font-size: 20px;
    
    color: #525252;
  /* Ciudad, sector, codigo postal... */




  
  }
  .semiTitle{
      /* Buscar por categoria */

font-family: CambayBold;
font-size: 35px;

color: #000000;

margin-left: 3vw;
  }
.buttonText{
    /* Publica tu carro */

    text-align: center;
font-family: CambayRegular;
font-size: 24px;


}
.signatureTitle{
    /* Carly */

font-family: CambayBold;
font-style: normal;
font-weight: bold;
font-size: 36px;
color: #F7F7F7;


}
.signatureTitle{
    /* Carly */

font-family: CambayBold;
font-style: normal;
font-weight: bold;
font-size: 30px;
color: #F7F7F7;


}   
.signatureElement{
    /* Beneficios */

font-size: 26px;
color: #F7F7F7;


}
.mainTitleMobile{
    position: relative;
    left:3%;
    width: auto;align-content: center;text-align: center;
    font-family: CambayBold;
    font-size: 34px;
    max-width: 90vw;
    color: #FFFFFF;
    mix-blend-mode: normal;
    z-index: 5;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .secondMainTitleMobile{

    width: 80vw;
    height: 68.34px;
    z-index: 5;
    position: relative;
    left:8%;
    font-family: "RobottoLight";
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    
    color: #F7F7F7;
    
  }