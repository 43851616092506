@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/components/modal.sass";


// .MuiPopover-paper{
//   min-height: 0px !important;
// }
.hoveredElement{
  background-color: #00a699 !important;
  width:100px;
  position:relative;
  bottom:30px;
  right:40px;
  width:100px
  
}
.currencyItem{
  font-size:15px !important;

  //padding-bottom: 4px;
  color:black !important;
  :hover{
    background-color: rgba(60, 117, 163, 0.336);
  }
}
span.MuiIconButton-label {
  font-size:18px !important;
  font-weight: 500;
}
.incomeBox{
  transition:all,2s,ease-in;
  background-color: rgba(221, 18, 45, 0.808)
}
.smallText{
  color:white;
  font-size: 14px;
  padding-bottom:20px;
  padding-right:20px;
  
}
.bigNumber{
  font-size: 26px;
  
  color:white;
  padding:20px;
  
}
.bigSymbol{
  font-size: 26px;
  padding:20px;
  font-weight: 600;
  color:white;
} 

.signatureItem{
  position:relative;
  left:11px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  border-radius: 6px;
  :hover{
    background-color: black !important;
    color:white
  }
}

.signatureHeader{
  position:relative;
  margin-bottom: 6px !important;
  left:10px;
}
.selectionBox{
  z-index: 900 !important;
}
p{
  margin-block-end: 0 !important;
  margin-block-start: 0 !important;
}
h1{
  margin-block-end: 0 !important;
  margin-block-start: 0 !important;
}
.MuiBox-root{
  padding:0;
  border:0;
  margin:0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.copyRightText{
  max-width: 720px;
  font-size: 10px;
  margin: auto auto 24px;
  color: #54545a;
  font-family: 'Open Sans',Tahoma,Geneva,sans-serif;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-transform: none;
}
#singleDates .DateInput{
  width:100%;
}
div.DateRangePickerInput .DateInput{
  width:40%;
}

html{
  z-index:-5 !important;
  overflow-x: hidden !important;
}
.stop-scrolling {
  height: 100%;
  overflow: hidden !important;
}
.mainBox{
  z-index:-5 !important;
}
body {
  padding:0px !important;
  z-index:-5 !important;
  background-color: white !important;
  margin: 0px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
header {
  padding: 0px !important;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}
.flex-no-shrink {
  flex-shrink: 0;
}
.modalPanel{
    height:800px;
    background-color:aqua;
}


#toggle{
  opacity: 0;
  height: 0 !important;
}
#toggle:hover{
  opacity: 1;
}
#myContainer {
	opacity: 1;
	-webkit-transition: .6s ease-in-out;
	transition: .6s ease-in-out;
}
#myContainer:hover #image {
  opacity: .5;
}
#myContainer:hover #toggle {
	opacity: 1 !important;
}
#myContainer:hover #floatingIcon {
	opacity: 1 !important;
}
#carousel div#verticalCarousel {
  height:auto;
  position:relative;
  top:35%;
  transform:translateY(50%);
}
#carousel button#siguiente {
  position:absolute;
  margin-right:0px;
}
.searchBox-Desktop{
  margin-top:100px;
  margin-left:100px;
  width:400px;
  z-index: 0 !important;
  position:relative;
}
.searchBox-Mobile{
  width:400px;
}
.divider{
  height:10px;
  width:100%;
}
#standard-required-label{
  color:white !important;
}
#standard-required.MuiInputBase-input{
  color:white !important;
}
/*
.MuiFormLabel-root{
  color:black !important;
}
*/
#standard-required.MuiFormLabel-root{
  color:white !important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity:0.8 !important;
}
::-moz-placeholder { /* Firefox 19+ */
  opacity:0.8 !important;
}
.salvacion{
  position:fixed !important;
  right:0 !important;
  top:0 !important;
  z-index: 1300 !important;
}
.listingCard{
  border-radius: 5px;
}
.listingCard:hover{
  background-color: rgba(45, 45, 45, 0.2);
  opacity: 0.85;
}
.floatingIcons{
  position:absolute !important;
  bottom:15px;
  left:50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  border-radius: 20px
}
.belowNotification {
  //
  padding-top:15px;
  padding-bottom:15px;
}
.notificationItem {
  //
}
.notificationItem :hover{
  background-color: #acacac
}
.MuiFormControl-marginNormal {
  margin-top:0px !important;
  margin-bottom:14px !important;
}
.backButton{
  background-color:gray;
  z-index:200;
  display:inline-block;
  height:60px;
  width:60px;
  opacity: 0.7;
  border-radius: 50%;
}
.backButton:hover{
  opacity: 1;
}
.nextButton{
  background-color:gray;
  z-index:200;
  display:inline-block;
  height:60px;
  width:60px;
  opacity: 0.7;
  border-radius: 50%;
}
.nextButton:hover{
  opacity: 1;
}
.lds-hourglass {
  margin-bottom:20px;
  z-index: 15;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #fff;
  border-color: rgb(170, 26, 26) transparent rgb(170, 26, 26) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

/* Basic Styles */



body {
  font-family: sans-serif;
  background-color: #F6C390;
}

a {
  text-decoration: none !important;
  //color: #FFF;
}
.white{
  color:white !important;
}
.container {
  position: relative;
  margin: 35px auto 0;
  width: 300px;
  height: 534px;
  background-color: #533557;
  overflow: hidden;
}
.MuiFormHelperText-contained{
  margin-top:0px !important;
}
.helpText{
  color: black !important;
  margin: 0;
  font-size: 1rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.03333em;
}
.MuiInputLabel-outlined {
	z-index: 0 !important;
}
.step{
  transition: all .4s ease;
  position: relative;
  bottom: 5px;
}
.step:hover{
  transform: scale(1.25,1.25);
}

@media (max-width:930px){
  .mainTitle{
  font-size: 38px;
}
}
p.semiTitle{
  font-weight: bold !important;
}
.captionTitle{
  font-family: BasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

span .MuiButton-label{
  font-weight: 800 !important;
}
.loginTitle{
  margin-bottom:26px !important;
}

.priceTaggy{
  font-size:22px;
  color:white;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius:5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
}
.floatingBox{
  position: absolute;
  top:10px;
  z-index: 9000;

}
.css-yk16xz-control{
  background-color: transparent !important;
}
div#getVerified .MuiStepButton-root{
  padding-top:0 !important;
  padding-bottom:0 !important;
}
div#getVerified .MuiStepper-root{
  padding-bottom:0 !important;
}
div#getVerified .MuiStepLabel-labelContainer{
  width:0px !important;
  height:0px !important;
}
#whiteDates .MuiInputBase-root{
  color:white !important;
}
//Borrowed STUFF
#whiteDates .MuiFormLabel-root{
  color:white !important;
}
#whiteDates .MuiIconButton-root{
  color:white !important;
}
#whiteDates .MuiInputBase-input{
  color:white !important;
}
.MuiFormLabel-root{
  color:black !important;
}
.DateInput_input__focused{
  border-bottom: 2px solid #f50057 !important;
}
.css-1pahdxg-control{
  border: 0.6px solid  #f50057 !important;
}
.CalendarDay__selected{
  background: #00a699 !important;
  border: 1px double #00a699;
}
.CalendarDay__selected_span:hover {
  background: #33dacd !important;
  border: 1px double #33dacd !important;
}
#addressLine:hover{
  background-color: gray; 
  opacity:0.8;
}

#addressLine:hover #floatingIcon{
  
  opacity: 1 !important;
  
}
.MuiTypography-caption{
  font-size: 0.85rem !important;
}
/*
.DateInput_input{
  color:white !important;
}*/


.adHeader{
  color: #231f20;
  font-family:"Ivar Headline", "Times New Roman", serif;
  font-weight:900;
  height:84px;
  letter-spacing:normal;
  line-height:42px;
}
.adSecondary{
  color:#231f20;
  display:block;
  font-family:"Open Sans", Tahoma, Geneva, sans-serif;
  font-size:18px;
  font-weight:400;
}
.adSecondaryBold{
  color:#231f20;
  display:block;
  font-family:"Open Sans", Tahoma, Geneva, sans-serif;
  font-size:22px;
  font-weight:700;

}

h1,
/*
.MuiDialogContent-root{
  overflow: hidden !important;
}*/
.css-2613qy-menu{
  //z-index: 9999 !important;
}
.contain {
  width: 100%;
}
.row {
  width: 100%;
}
.row__inner {
  transition: 450ms transform;
  font-size: 0;
  white-space: nowrap;
  margin: 70.3125px 0;
  padding-bottom: 10px;
}
.tile {
  position: relative;
  display: inline-block;
  width: 250px;
  height: 140.625px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  
}

.indiana-scroll-container--hide-scrollbars{
  box-sizing: content-box;
  overflow-y:hidden !important;
  overflow-x:scroll;
  -webkit-overflow-scrolling:touch !important;
}
.tile__img {
  width: 250px;
  height: 140.625px;
  object-fit: cover;
  
}
.selectedCategory{
  border: solid #f50057 10px !important;
}

.tile__details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 10px;
  opacity: 0.85;
  background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
  transition: 450ms opacity;
}

.tile__details:after,
.tile__details:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: #000;
}
/*
.tile__details:after {
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border: 3px solid #ecf0f1;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1;
}
*/
.tile__details:before {
  //content: '▶';
  left: 0;
  width: 100%;
  font-size: 30px;
  margin-left: 7px;
  margin-top: -18px;
  text-align: center;
  z-index: 2;
}
.tile:hover .tile__details {
  opacity: 1;
}
.tile__title {
  position: absolute;
  bottom: 0;
  left:25%;
  padding: 10px;
  color:white !important;
  font-size:30px;
}
.row__inner:hover {
  transform: translate3d(-62.5px, 0, 0);
}
.row__inner:hover .tile {
  opacity: 0.3;
}
.row__inner:hover .tile:hover {
  transform: scale(1.5);
  opacity: 1;
}
.tile:hover ~ .tile {
  transform: translate3d(125px, 0, 0);
}

/*--------------------
Mixins
--------------------*/
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ball {
  @include center;
  content: '';
  display: block;
  width: 3px;
  heighat: 3px;
  border-radius: 50%;
  background: rgba(255, 255, 255, .5);
  z-index: 2;
  margin-top: 4px;
  animation: ball .45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}


/*--------------------
Body
--------------------*/
// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

// html,
// body {
//   height: 100%;
// }

// body {
//   background: linear-gradient(135deg, #044f48, #2a7561);
//   background-size: cover;
//   font-family: 'Open Sans', sans-serif;
//   font-size: 12px;
//   line-height: 1.3;
//   overflow: hidden;
// }

// .bg {
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   background: url('https://images.unsplash.com/photo-1451186859696-371d9477be93?crop=entropy&fit=crop&fm=jpg&h=975&ixjsv=2.1.0&ixlib=rb-0.3.5&q=80&w=1925') no-repeat 0 0;
//   filter: blur(80px);
//   transform: scale(1.2);
// }


/*--------------------
Chat
--------------------*/
.chat {
  @include center;
  //height: 80vh;
  max-height: 500px;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, .2);
  background: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


/*--------------------
Chat Title
--------------------*/
.chat-title {
  flex: 0 1 45px;
  position: relative;
  z-index: 2;
  //background: rgba(0, 0, 0, 0.2);
  background: rgba(192, 192, 192, 0.884);
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  padding: 10px 10px 10px 50px;
  
  h1, h2 {
    font-weight: normal;
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
  
  h2 {
    color: rgba(255, 255, 255, .5);
    font-size: 8px;
    letter-spacing: 1px;
  }
  
  .avatar {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 9px;
    border-radius: 30px;
    background-size: cover;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 2px solid rgba(255, 255, 255, 0.24);
    
    img {
      width: 100%;
      height: 100%;
    }
  }
}


/*--------------------
Messages
--------------------*/
.messages {
  flex: 1 1 auto;
  color: rgba(255, 255, 255, .5);
  overflow: hidden;
  position: relative;
  width: 100%;
  
  & .messages-content {
    position: absolute;
    top: 0;
    left: 0;
    padding-left:7px;
    padding-right:7px;
    height: 101%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .messages-content::-webkit-scrollbar {
    width: 0.75em;
}
 
.messages-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.messages-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
  
  .message {
    clear: both;
    float: left;
    padding: 6px 10px 7px;
    border-radius: 10px 10px 10px 0;
    //background: rgba(0, 0, 0, .3);
  background: rgba(192, 192, 192, 0.884);
    margin: 5px 0;
    font-size: 11px;
    line-height: 1.4;
    min-width: 50px;
    margin-left: 35px;
    position: relative;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    .timestamp {
      color:black !important;
      position:relative !important;
      right:0px;
      top:3px;
      bottom: -15px;
      font-size: 9px;
      //min-width: 300px;
      color: rgba(255, 255, 255, .3);
    }
    
    &::before {
      content: '';
      position: absolute;
      bottom: -6px;
      //border-top: 6px solid rgba(0, 0, 0, .3);
      //rgba(192, 192, 192, 0.884)
      border-top: 6px solid rgba(192, 192, 192, 0.884);
      left: 0;
      border-right: 7px solid transparent;
    }
    
    .avatar {
      position: absolute;
      z-index: 1;
      bottom: -15px;
      left: -35px;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 2px solid rgba(255, 255, 255, 0.24);

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    &.message-personal {
      float: right;
      color: #fff;
      text-align: right;
      background: linear-gradient(120deg, #248A52, #257287);
      border-radius: 10px 10px 0 10px;
      .timestamp {
        position:relative !important;
        right:0px;
        top:3px;
      }
      &::before {
        left: auto;
        right: 0;
        border-right: none;
        border-left: 5px solid transparent;
        border-top: 4px solid #257287;
        bottom: -4px;
      }
    }
    
    &:last-child {
      margin-bottom: 30px;
    }
    
    &.new {
      color:black;
      transform: scale(0);
      transform-origin: 0 0;
      animation: bounce 500ms linear both;
    }
    
    &.loading {

      &::before {
        @include ball;
        border: none;
        animation-delay: .15s;
      }

      & span {
        display: block;
        font-size: 0;
        width: 20px;
        height: 10px;
        position: relative;

        &::before {
          @include ball;
          margin-left: -7px;
        }

        &::after {
          @include ball;
          margin-left: 7px;
          animation-delay: .3s;
        }
      }
    }
    
  }
}


/*--------------------
Message Box
--------------------*/
.message-box {
  flex: 0 1 40px;
  width: 100%;
  //background: rgba(0, 0, 0, 0.3);
  //background: rgba(192, 192, 192, 0.884);
  border-width: 1px !important;
  border-color: #000 !important;
  padding: 10px;
  position: relative;
  
  & .message-input {
    background: none;
    border: none;
    outline: none!important;
    resize: none;
    //color: rgba(255, 255, 255, .7);
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 11px;
    height: 17px;
    margin: 0;
    padding-right: 20px;
    width: 265px;
  }
  
  textarea:focus:-webkit-placeholder{
      color: transparent;
  }
  
  & .message-submit {
    position: absolute;
    z-index: 1;
    top: 9px;
    right: 10px;
    color: #fff;
    border: none;
    background: #248A52;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px; 
    border-radius: 10px;
    outline: none!important;
    transition: background .2s ease;
    
    &:hover {
      background: #1D7745;
    }
  }
}


/*--------------------
Custom Srollbar
--------------------*/
.mCSB_scrollTools {
  margin: 1px -3px 1px 0;
  opacity: 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0px;
  padding: 0 10px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.5)!important;
}


/*--------------------
Bounce
--------------------*/
@keyframes bounce { 
  0% { transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% { transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% { transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% { transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% { transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% { transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% { transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% { transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% { transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% { transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% { transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% { transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}


@keyframes ball { 
  from {
    transform: translateY(0) scaleY(.8);
  }
  to {
    transform: translateY(-10px);
  }
}

//Continue with FB
a.fb {
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  font-size: 14px;
  padding: 13px 30px 15px 44px;
  background: #3A5A97;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,20,.4);
  text-decoration: none;
  line-height: 1;
  position: relative;
  border-radius: 5px;
}

.fb.connect::before {
  display: inline-block;
  position: relative;
  //background-image: url("/svg/fbicon.png");
  height: 23px;
  background-repeat: no-repeat;
  background-position: 0px 3px;
  text-indent: -9999px;
  text-align: center;
  width: 29px;
  line-height: 23px;
  margin: -8px 7px -7px -30px;
  padding: 2 25px 0 0;
  content: "f";
}
a.google {
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  font-size: 14px;
  padding: 13px 30px 15px 44px;
  background: rgb(190, 48, 38);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,20,.4);
  text-decoration: none;
  line-height: 1;
  position: relative;
  border-radius: 5px;
}
#hiddenHoverContent{
  display:none;
}
#generalDropDown:hover #hiddenHoverContent{
  z-index: 9999;
  position:absolute;
  margin-left:-15px;
  background-color: white;
  display:block;
}
#currencySteps{
  display:none;
}
#currencyContainer:hover #currencySteps{
  position:absolute;
  margin-left:-25px;
  background-color: white;
  display:block;
}
/* Dropdown Button */
.dropbtn {
  background-color: rgb(0, 112, 186);
  color: white;
  max-width: 90px;
  padding: 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.arrowDownward{
  color:black;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99999 !important;

}
@media (max-width:550px){
  .left{

    left:-60px;
  }
  .right{
    right:0px;
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #b9b9b9;}
.dropdown:hover .dropdown-content {
  display: block;z-index: 9999;}
.dropdown:hover .dropbtn {
  z-index: 9999;
  background-color: #100192f1;
  max-width: 120px;
}
.google.connect::before {
  display: inline-block;
  position: relative;
  //background-image: url("/svg/googleicon.png");
  height: 23px;
  background-repeat: no-repeat;
  background-position: 0px 1px;
  text-indent: -9999px;
  text-align: center;
  width: 29px;
  line-height: 23px;
  margin: -8px 7px -7px -30px;
  padding: 2 25px 0 0;
  content: "f";
}

.hover15 figure {
	position: relative;
}
.hover15 figure::before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255,255,255,.2);
	border-radius: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
}
.hover15 figure:hover::before {
	-webkit-animation: circle .75s;
	animation: circle .75s;
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}

.lds-roller {
  z-index: 5555;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(170, 26, 26);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.popOverTrigger:hover{
  #customPopOver{
    display:block !important;
  }
}
#customPopOver{
  z-index: 99999 !important;
  display:none;
  background-color:rgb(255, 255, 255);
  width:390px;
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  box-shadow:rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  box-sizing:border-box;
  color:rgba(0, 0, 0, 0.87);
  font-family:sans-serif;
  //height:24px;
  padding:20px;
  max-height:calc(100% - 32px);
  max-width:calc(100% - 32px);
  min-height:16px;
  min-width:16px;
  opacity:1;
  outline-color:rgba(0, 0, 0, 0.87);
  outline-style:none;
  outline-width:0px;
  overflow-x:hidden;
  //overflow-y:auto;
  position:fixed;
  transform:none;
  transform-origin:-1px 0px;
  transition-delay:0s, 0s;
  transition-duration:0.177s, 0.118s;
  transition-property:opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
}

.mySpecialButton {
  --top: 50%;
  --left: 50%;
  --size: 100%;
  --background: hsl(265, 100%, 50%);
  --color: hsl(265, 100%, 100%);
  --border: none;
  color: var(--color);
  background: var(--background);
  border: 1px solid var(--border);
  font-family: inherit;
  padding: 0.6rem 1.25rem;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  overflow: hidden;
  position: relative;
  outline: none;
}
 
.mySpecialButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  border-radius: inherit;
  opacity: 0;
}
.mySpecialButton:focus:before,
.mySpecialButton:hover:before {
  opacity: 0.1;
}
 
.mySpecialButton:after {
  content: "";
  position: absolute;
  top: var(--top);
  left: var(--left);
  transform: translate(-50%, -50%);
  width: var(--size);
  height: var(--size);
  background: currentColor;
  clip-path: circle(0%);
  opacity: 0.3;
  border-radius: inherit;
}
 
.mySpecialButton:active:after {
  clip-path: circle(100%);
  opacity: 0;
  transition: clip-path 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53), opacity 0.4s ease-in-out;
  transition-delay: -0.1s, 0.5s;
}