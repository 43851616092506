html{
  overflow-x: hidden;
}

form {
	margin: 20px;
	padding: 20px;
	background: #FFF;
}

.material {
	position: relative;
	padding: 0;
	margin: 5px;
	border: none;
	overflow: visible;
	
	input {
		box-sizing: border-box;
		width: 100%;
		padding: 12px 10px 8px;
		border: none;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid #DDD;
		font-size: 120%;
		outline: none;
		cursor: text;

		&::-webkit-input-placeholder {
			transition: color 300ms ease;
		}
		&:not(:focus)::-webkit-input-placeholder {
			color: transparent;
		}
	}
	
	hr {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 2px;
		border: none;
		background: #607D8B;
		font-size: 1px;
		will-change: transform, visibility;
		transition: all 200ms ease-out;
		transform: scaleX(0);
		visibility: hidden;
		z-index: 10;
	}
	input:focus ~ hr {
		transform: scaleX(1);
		visibility: visible;
	}
	
	label {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 120%;
		color: #607D8B;
		transform-origin: 0 -150%;
		transition: transform 300ms ease;
		pointer-events: none;
	}
	input:focus ~ label,
	input:valid ~ label {
		transform: scale(0.6);
	}
}

.currencyDiv{
  position:relative;
  z-index: 30 !important;
}
.currencyInnerDiv{
  position:absolute;
  top:34px;
  background-color: white;
  z-index:5;
}

.currencyDiv:hover .currencyInnerDiv{
  z-index:5;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: repeat(3,auto);
}
/* .currencyDiv:hover .selectedCurrency{
  display: none;
} */
.selectedCurrency{
  z-index:5;
  padding:12px;
}
.currencyInnerDiv{
  display: none;
  z-index: 30;
  padding:10px;
}
body{
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0px !important;
}
input{
  padding:0;
}
.innerBg{
  position: absolute;
  top:17vh;
  display:grid;
  width:100%;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 4fr;
  align-items: center;
}

.mobileNav{
  width:100%;
  position:relative;
  background-color: white;
  z-index: 1000;
  display:flex;
  height:120px;
}
.underLine{
  font-weight: 600;
  color:black !important;
  text-decoration-line: underline;
}
.alertBg{
z-index: 2;
  position: absolute;top:0px;
  width: 100%;
 height: 60px;
  display: flex;
  justify-content: center;
  background-color: #F7F7F7;
}

.mainCards{
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
}
.mainCard{
  margin:0.5rem;
  position:relative !important;
  width: 380px ;
height: 380px;

background: #FFFFFF;
border-radius:10px;
box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}
.mainButtonLetter{
  font-family: Neuton;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;
/* identical to box height */

letter-spacing: 0.01em;

color: black;

}
.mainButton{
  position: absolute;
width: 86px;
height: 35px;
left: 127px;
top: 344px;

background: #FFFFFF;
border: 0.45px solid #000000;
}
.mainInfo{
  position: absolute;
width: 313px;
height: 60px;
left: 38px;
top: 260px;

font-family: Puritan;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 20px;
text-align: center;
letter-spacing: 0.01em;

color: #000000;

}
.mainCardSvg{
  position:absolute;
  top:25px;
  width:160px;
  height:160px;
}
.mainCardTitle{
position: absolute;
top:205px;
font-family: 'Neuton', serif;
font-style: normal;
font-weight: 600;
font-size: 29px;
line-height: 37px;
/* identical to box height */

letter-spacing: 0.01em;

color: #000000;
}
.innerMobileSearch{
  display: flex;
  width: 91vw;
  align-items: center;
  height: 43px;
  border-color: black;
  box-shadow: -5px 10px 15px rgba(0, 0, 0, 0.10);
  border-radius: 60px;
}
.innerMobileSearchSmall{
  display: flex;
  width: 15vw;
  align-items: center;
  height: 43px;
  border-color: black;
  box-shadow: -5px 10px 15px rgba(0, 0, 0, 0.10);
  border-radius: 60px;
}
.mobileSearchBarSmall{
  z-index:3;
  background-color: white;
  width: 100%;
  display: grid;
  position:fixed;
  top:0px;
  grid-template-columns:1fr 1.6fr;
  grid-template-rows: 65px;
  height: 65px;
}
.mobileSearchBar{
  z-index:1000;
  background-color: white;
  width: 100%;
  display: none;
  position: absolute;
  top:60px;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-bottom: 0.1px solid #a3a3a3;
}
.whiteBg{
  background-color: white;
}
.normal{
  font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 300;
height:80px;
max-width: 400px;
font-size: 15px;
line-height: 18px;
text-align: center;
 
}
.mainAlert{
  background-color: #F7F7F7;
}

@media (min-width:100px) {
  .card{
    width:90vw !important;
  }



}
@media (min-width:645px) {
  .card{
    width:45vw !important;
  }
}
@media(min-width:920px){
  .card{
    width:27vw !important;
  }
  .fieldBox{
    width: 100% !important;
  }
}
@media (min-width:760px) {
  .mobileBackground {
    display: none !important;
  }
  .bottomNavbar{
    display: none !important;
}
  .mainListingdiv{
    display: grid;
    margin-left: 0.8rem;
    column-gap: 2.5rem;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 84vh;
  }
  .modalLoginCard{
    height: 80vh !important;
  }

  .floatingIcon{
    display: none !important;
}
  .bottomNavbar2{
    display: none !important;
}
  .mobileNav{
  display: none !important;
}
.mobileNavListings{
  display: none !important;
}
.cards{
  width: 100% !important;
  flex-wrap: wrap;
}

.mainListingGrid{
  display:flex;
}
.mobileSearchBar {
  display: none !important;
}
.topImage{
  height: 400px !important;
}
}
@media (max-width:760px) {

  .mainListingGrid{
    display:grid;
    grid-template-columns: 1fr !important;
    grid-template-rows:auto;
  }
  .buttonText{
    font-size: 16px !important;
  }
    .signatureItem{
      font-size: 13px !important;
    }
    .signatureTitle{

      font-size: 25px !important;}
      .secondMainTitle{
        font-size: 18px !important;
      
}
  .map{
    display:none;
  }
  .card{
    width: 70vw;
  }
  .cards{
    box-shadow: none !important;
  }
  .background{
      display: none !important;
  }
  .mobileBackground{
    display: flex;
    align-items: center;
    justify-items: center;
    background-size: 155vh 120%;
    background-position-x: 80%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-image: url(./media/pictures/carenportada.webp);
    background-image: --webkit-image-set(url(./media/pictures/carenportada.jpg) 1x) !important;
    background-image: image-set(url(./media/pictures/carenportada.jpg) 1x) !important;

    min-height:85vh;
    height: 90vh;
    overflow-x: hidden !important;
    position: relative;
    overflow-y: hidden !important;
}
#root{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
  /* .indexNavBar{
  display: none !important;
} */
.navBarTitle{
  font-family: Puritan;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 17px;
}
.navBar{
  display: none !important;
}
  .hugeSearch{
      width:80vw !important;
  }
  .mainTitle{
      font-size: 30px !important;
  }
}
.middleLogo{
}
.leftWing{
  display: flex;
  margin-left: 30px;
}
.baseBox{
  font-family: CambayBold;
  transition: 0.1s ease-in border;
}
.cambayBold{
  font-family: CambayBold;
}
.baseBox:hover{
  cursor:pointer;
  border: 3px solid #655CB3;
}
.selectedBox:hover {
  border: 4px solid #655CB3 !important;
}
.greyBox{
  /* Rectangle 55 */
  cursor:default !important;
  border:none !important;
background: #DFDFDF;
border: 2.4px solid #DFDFDF;
box-sizing: border-box;
/* No tienes ningun viaje hoy */


font-family: CambayBold;
font-size: 17px;

}
.selectedBox{
  /* Rectangle 55 */


background: #FFFFFF;
border: 2.4px solid #655CB3;
box-sizing: border-box;

}
.shadowBox{
  /* Rectangle 62 */
  min-width: 50%;
max-width: 80%;
height: 55px;
padding-left:24px;
padding-right:24px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;

}
.agendaBox{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-rows: 20px 65px 65px 65px 65px 65px;
}
.rightWing{
  position:relative;
  right:5.5vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width:1311px){
  .background{
    background-size: 160% 100% !important;
    background-position-x: 70%;
  }

}
.background{
  display: flex;
  align-items: center;
  justify-items: center;
  background-size: 110vw 100%;
  background-position-x: 70%;
  background-image: url(./media/pictures/carenportada.webp);
  background-image: --webkit-image-set(url(./media/pictures/carenportada.jpg) 1x) !important;
  background-image: image-set(url(./media/pictures/carenportada.jpg) 1x) !important;
  z-index: 99;
  height:80vh;
}

.mobileLogo{
  position:absolute;
  top:0px;
display: grid;
grid-template-columns: 3fr 1fr 3fr;
grid-template-rows: 60px;
width: 100vw;
}

#hugeInput2{
  width: 100%;
  margin-left: 28px;
}
#hugeInput2::placeholder{

font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 4px;
line-height: 14px;
letter-spacing: 0.01em;

color: #969696;


}
#hugeInput{
  outline:0px !important;
  border:0px !important;
  margin-top: -7px;
}

.blurryHolder{
  width: 495px;
height: 20px;
left: 347px;
top: 320px;

font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 30px;
letter-spacing: 0.01em;

color: #969696;
}
.box1{
  align-items: center;
  justify-content: flex-start;
  display:flex;
}

.box2{
  justify-content: flex-end;
  align-items: center;
  display:flex;
}
.suggestSearch{
  display: none;
  height: 100px;
  background-color: white;
}
.hugeBox{
  min-width: 799px;
  border: 4px solid transparent;
  background: #FFFFFF;
  box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.1);
  border-radius: 45px;
  transition: height,grid-template-rows ease-in 2s;
  max-width: 800px;
}
.inner{
  z-index: 2;
}
.hugeBoxMobile{
  margin:1rem;
  margin-top:1rem;
  position: relative;padding: 8px;
  z-index: 99;
  min-width: 70vw;
  border: 4px solid transparent;
  background: #FFFFFF;
  box-shadow: 0px 12px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: height,grid-template-rows ease-in 2s;
}
.material-textfield input{
 box-sizing: border-box;
}
.DateInput_input{
  box-sizing: border-box;
}
.mainBody{
  width:100% !important;
  box-sizing: border-box;
}
.SingleDatePicker{
  width: 100%;
}
.hugeSearchMobile{
  border: 4px solid transparent;
    display:grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 58px;
    position: static;
    top:200px;
    align-items: center;
    z-index: 4;
    width: 70%;
    height: 58px;
    background: #FFFFFF;
    border-radius: 3px;
}
.hugeSearch{
  display:grid;
  grid-template-columns: 5fr 4fr 1fr;
  grid-template-rows: 90px;
  position: relative;
  align-items: center;
  z-index: 4;
  right:7px;
  width: 100%;
  min-width: 810px;
  height: 90px;
  

}
#suggestSearch{
  position: relative;
  right:5px;
  width: 100% !important;
  top:2px;
  border-top: 2px solid black;
  display:none;
}
.hugeBox.suggestions{
  display:grid;
  height: auto !important;
}
/* Rectangle 6 */



.hugeBox:hover{
  border: 4px solid black;
  
}
.hugeSearchMobile:hover{
  border: 4px solid black;
  
}
.whiteNavBar{
  color:white;
}
.blackNavBar{
  background-color: white !important;
  color:black;
}
.transparentNavBar{
  color:white;
}
.indexNavBar{
  align-items: center;
  z-index: 100;
  top:0px;
  min-width:100%;
  position: fixed;
  display:grid;
  height: 65px;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: 65px;
}
.navBar{
  background-color: white;
  z-index: 2;
  top:0px;
  min-width: 100vw;
  position: fixed;
  display:grid;
  height: 60px;
  grid-template-columns: 1fr 3fr 3fr 3fr;
  grid-template-rows: 60px;
}
.invisible{
  border:0px;
  outline:0px;
}
.mainInput{
  margin-left: 0px;
  width:80%;
  border:0px;

}
.mainInput:focus{
  outline:0px !important;
  border:0px !important;
}
.searchIcon{
  margin-left: 5px;
}
.offButton{
/* Rectangle 45 */
display: flex;
align-items: center;
width: 122.44px;
height: 37.29px;
border: 0.2px solid #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.botones{
  display: flex;
  position:static;
  width: 246px;
  height: 37.29px;
}
.smallButtonText{
  font-family: 'Roboto', sans-serif, medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.025em;
}
.grey1{
  font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.025em;

color: #404040;


}
.white{
  color: #FFFFFF;
}
.buttonClass{
    cursor: pointer;
}
button{
  color:white;
  border-color:white;
  outline:0px;
  cursor: pointer;
  padding:0px;
  border-width: 0px;
}
button:active{
  outline:0px;
}
.offButton:hover{
  background: #469c189f !important;
}
/* .onButton:hover{
  background: #2863089f !important;
} */
.offButton .smallButtonText{
  color:grey;
}
.onButton{
  align-items: center;
  /* Rectangle 45 */
  width: 122.44px;
  height: 37.29px;
  background: black;
  
  border-radius: 10px;
  display: flex;
  }
.mainSearch{
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  width:30vw;
  margin-right: 10px;
  height:40px;
  background: #FFFFFF;
  border: 0.1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.leftNav{
  padding-left: 5px;
}
.centerNav{
}
.rightNav{
}

.centerY{
  transform: translateY(-50%);
  position: relative;
  top:50%;
}
.centerX{
  transform: translateX(-50%);
  position: relative;
  left:50%;
}
.sendRight{
  margin-left: 5px;
}
.floatingNum{
  position:relative;
  right:11.5px;
  bottom:10px;
  font-size: 12px;
  z-index: 2;
  color:white;
  font-weight: 600;;
}
.rightNav{
  display: flex;
  justify-content: flex-end;
}
.map{
  background-color: gray;
}
.mainListingGrid{
  display:grid;
  grid-template-columns: 1.45fr 1fr;
  grid-template-rows:auto;
}
.cards{
  min-height:60vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 8px 4px 10px rgba(0, 0, 0, 0.25);

}
.card{
  margin:10px;
  padding-bottom:20px;
  position: relative;
  width: 350px;
  height: 200px;
}
.screener{
  position: absolute;
  bottom: 48px;
  z-index: 1;
  height: 30% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.48);
  transition: none !important;
}
.locationText{
  z-index: 2;
  font-family: 'Roboto', sans-serif, medium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.precio{
  z-index: 2;
  width: 230.21px;
  height: 25.43px;
  font-family: 'Roboto', sans-serif, medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.045em;
  
  color: #FFFFFF;
  
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.flexEnd{
  display: flex;
  justify-content: flex-end;
}
.flexStart{
  display: flex;
  justify-content: flex-start;
}
.flexWrap{
  display: flex;
  flex-wrap: wrap;
}
.smallText1{
  margin:5px;
}

html{
  box-sizing: border-box !important;
}
.overflowHidden{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.bottomCard{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  height: 44.85px;
  
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}


.justify{
  justify-content: center;
}

.registerText{
  font-family: Puritan;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 17px;
letter-spacing: 0.01em;

color: #000000;

}
.registerButton{
  width:100px;
  height:50px;
  background: #FFFFFF;
  border: 0.1px solid #000000;
  border-radius: 30px;
}


.selectedBorder{
  border: 4px solid black
}

.resultsText{
font-family: 'Roboto', sans-serif, medium;
font-style: normal;
font-weight: 500;
font-size: 22px;
/* identical to box height */
margin:0px !important;
letter-spacing: 0.025em;

color: #000000;

}

.optionBox{
  position:relative;
  z-index: 2;
  width: 100%;
  height:46px;
  background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 5px;
}
.wideText{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
font-weight: bold;
font-size: 17px;
line-height: 20px;
/* identical to box height */


color: rgba(255, 255, 255, 0.9);
}
.wideButton{
  z-index: 1;
  display: flex;
  width: 100%;
  height: 40px;
  background: black;
  border-radius: 5px;
}
.upperFieldBox{
  border-bottom: 0px !important;
  border-radius: 8px 8px 0px 0px !important;
}
.smallText2{
  width: 66px;
height: 14px;

font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #000000;

}
.bottomFieldBox{
  border-radius: 0px 0px 8px 8px !important;
}

.normalText::placeholder{
  z-index: 3  ;
  height: 19px;
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

color: #000000;
}
.fieldBox{
  display:flex;
  padding-left: 1rem;
  align-items: center;
  width: 100%;
height: 57px;

border: 0.8px solid #000000;
box-sizing: border-box;
border-radius: 8px;
transform: rotate(0);
}
.closeIcon{
  position: absolute;
  left:18px;
}

.secondTitle{
  
  font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;
color: #1F1F1F;

}
.upperCard2{
  position: absolute;
  top:0px;
  display: flex;
  align-items: center;
  width: 100%;
}
.upperCard{
  position: absolute;
  top:0px;
  display: flex;
  align-items: center;
  width: 100%;
  height:50px;
  border-bottom: 0.1px solid black;
}
.normalText{

height: 19px;
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

color: #000000;

}
/* BOTTOM */
.middleIcon{
  display: flex;
  justify-content: center;
}
.selected{
  fill:black !important;
}
.floatingIcon{
  position:fixed;
  z-index: 30;
  bottom:80px;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  border-radius: 30px;
  width: 150px;
  height: 40px;
  border: 1px solid black;
  background-color:white
}
.bottomNavbar2{
  z-index: 5;
  width:100%;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 65px;
  background-color:white;
  border-top: 0.2px solid #484848;
  box-sizing: border-box;
  position: fixed !important;
  bottom:0px !important;
  
  color:white;
  display:grid;
  grid-template-columns:2fr auto 2fr;

}

.bottomNavbar{
  z-index: 10;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  background-color:white;
  border-top: 0.2px solid #484848;
  position: fixed;
  bottom:0px;
  height:65px;
  color:white;
  display:grid;
  grid-template-columns:repeat(5,20vw);

}

.bottomIcon{
  text-align: center;
  position:relative;
}

/* MODAL */

.modal{

  position: fixed;
  width:100vw;
  height:100vh;
  background-color:rgba(0, 0, 0, 0.74);}
p{
  margin:0px;
}
.centerCard{
  margin-top:28px;
  display: grid;
  grid-template-columns: 1fr;

}
.centerCard2{
  margin-top:28px;
  display: grid;
  grid-template-columns: 1fr;
  padding:20px;
}
.mobileLogin{
  display:flex;
  position: absolute;
width: 100%;
height: 604px;
background: #FFFFFF;
border-radius: 20px;
}
.modalLoginCard{
  position: absolute;
width: 533px;
height: 604px;
background: #FFFFFF;
border-radius: 20px;
} 
.modalLoginCardMobile{
  position: absolute;
width: 90vw;
height: 90vh;
background: #FFFFFF;
border-radius: 20px;
}
a{text-decoration: none;}

.mobileTitle{
  position: absolute;
  top:1.5rem;
  font-family: 'Roboto', sans-serif,bold;
  font-weight: 600 !important;
  font-size: 36px !important;
  color: #1F1F1F; 
}

.logoMini{
  height:60px;
  width:100px;
  background-size: cover;
  background-image: url(./media/pictures/CarlyLogo.png);
}

.wide{
  width:100%!important;
}
.long{
  height:100%!important;
}
.modalPanel{
  height:800px;
  background-color:aqua;
}
.modal-card-title{
padding-left:12px;
padding-right:12px;
margin-block-start: 15px !important;
margin-block-end: 15px !important;
}

.modal-card{
  box-sizing: content-box;
  width: 100%;
height:800px;
}
.modal-card-body{
z-index: 100;
overflow-x: hidden !important;
padding: 0 !important;
}
.modal-card-foot{
padding: 12px !important;
}

.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 92%;
}

@media screen and (min-width: 769px), print {
  .modal-content,
  .modal-card {
    max-height: calc(100vh - 40px);
    width: 92%;
    box-sizing: content-box;
  }
}

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}
.modal-card-foot{
  padding: 12px !important;
}
.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}