/* Perfil */
.profilePicture{
    position: absolute;
    width: 43px !important;
    height: 43px !important;
    background-size: contain;
    border-radius: 50%;
    background-image: url(https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png);
}
.profilePicture:hover .mainHover{
    display:block;
  }
.blackScreen{
    /* Rectangle 45 */

position: absolute;
width: 100%;
height: 72vh;
top: -165px;
z-index: 1;
background: linear-gradient(180.35deg, #000000 0.31%, rgba(0, 0, 0, 0) 118.43%);

}
.customField{
    display:grid;
    padding:1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
}
.greenEdge{
    /* Rectangle 49 */
position: relative;
height: 100%;
width: 100%;
border: 4px solid black;
background: black;
border-radius: 0px 40px 40px 0px;

}
.greenEdge.edgy{
  /* Rectangle 49 */
  border-radius: 0px 40px 0px 0px !important;
}
.mainHover{
    padding:8px;
    padding-left: 14px !important;
    padding-right: 14px !important;
    display:none;
    position: absolute;
    top:38px;
    right:-1.5rem;
    z-index: 102;
    min-width: 160px;
    background: #FFFFFF;
    border: 3px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    /* Rectangle 48 */
  
  
  }
  body{
    overflow: hidden !important;
  }
  .botonBlanco{
height: 55px;
padding-left: 18px;
padding-right: 18px;
border: 2px solid #FFFFFF;
border-radius: 15px;

  }
  .botonNegro{
    height: 55px;
    padding-left: 18px;
    padding-right: 18px;
    border: 2px solid #000000;
    border-radius: 15px;
    
      }
      .botonVerde{
        height: 55px;
        padding-left: 18px;
        padding-right: 18px;
        border: 2px solid #00CD14;
        background-color: #00CD14;
        border-radius: 15px;
        
          }
          .botonNegro:hover{
            cursor: pointer;
            text-decoration: none;
          }
          .botonBlanco:hover{
            cursor: pointer;
            text-decoration: none;
          }
          .botonVerde:hover{
            cursor: pointer;
            text-decoration: none;
          }
  #notifications:hover .mainHover{
    display:block;
  }
  .categorySlider{
    transition: transform 0.6s ease-in-out;
    -webkit-overflow-scrolling: touch;
    overflow-x:scroll;
    box-sizing: content-box;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr;
    grid-row: 1fr;
    
  }
  @media (min-width:860px) {
    .categorySlider{
    overflow-x:hidden !important;}
  }
  .link:hover{
    cursor: pointer;
    text-decoration: none;
    
  }
  .categorySlider::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
  .categoryDrawer{
    overflow: hidden;
      display: grid;
      grid-template-columns: 0.8fr 9fr 0.8fr;
      grid-row: 1fr;
      margin-top: -20px;
      margin-bottom:50px;
      position: relative;
      z-index: 100 !important;
  }
  .categoryCard{
    min-height:100px;
    min-width: 240px;
    
      padding:20px;
    grid-template-columns: 25vw;
    grid-row: 180px 4vw;
  }
  .upperCategoryCard{
width: 100%;
height: 13vw;
max-height: 140px;
background-size: 100%;
background-repeat: no-repeat;
border-radius: 15px 15px 0px 0px;
  }
  .bottomCategoryCard{
      min-height: 40px !important;
width: 100%;
height: 4vw;
max-height: 40px;
background: #FFFFFF;
box-shadow:  4px  4px 4px rgba(0, 0, 0, 0.15);
border-radius: 0px 0px 5px 15px ;


}

.vehicleDrawer{
    /* display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    grid-row: 1fr; */
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:-50px;
}
.vehicleCardy{
  cursor: pointer;
  text-decoration: none;
}
.vehicleCardy{
  position:relative;
  padding:10px;
  padding-top:40px;
  padding-bottom: 40px;
  flex-shrink: 3 !important;
  min-width: 28vw !important;
    height: 18vw;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70% 30%;
  border-radius: 15px !important;
}
@media (max-width:759px){
  .indexNavBar{
    position:absolute !important;
    top:0px;
  }
  .blackScreen{
    height: 102vh !important;
  }
  .upperCategoryCard{
    min-height: 90px !important;
    max-height: 140px !important;
    height: 90px !important;
  }
  .categoryCard{
    padding:10px !important;
    min-width:170px !important;
    min-height:120px !important;
    grid-template-columns: 10px !important;
  }
}
@media (max-width:1000px){
  .upperVehicleCardy{
    height: 55vw !important;
    width: 92vw !important;
  }
  .vehicleCardy{
    padding-top:40px !important;
    padding-bottom: 25px !important;
    height: 55vw !important;
    width: 92vw !important;
  }
}
@media (min-width:1001px){
  .upperVehicleCardy{
    height: 30vw !important;
    width: 40vw !important;
    background-size: 130% 100% !important;
  }
  .vehicleCardy{
    padding-top:20px !important;
    padding-bottom: 20px !important;
    height: 30vw !important;
    width: 40vw !important;
    background-size: 130% 100% !important;
  }
}
.upperVehicleCardy{
    /* Rectangle 16 */

width: 100%;
min-width: 300px;
min-height: 280px;
background-size: 120% 100%;
background-position-x: 40%;
background-image: url(../media/pictures/hondacivic.jpg);
background-repeat: no-repeat;
border-radius: 15px !important;
position: relative;
color:white;
}
.bottomVehicleCardy{
    /* Rectangle 17 */

width: 100%;
height: 4vw;
min-height: 45px;
/* Rectangle 17 */
position: absolute;
bottom:0px;
background: rgba(0, 0, 0, 0.88);
box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
border-radius: 0px 0px 15px 15px;

/* Rectangle 46 */




}
#mainBody{
  overflow-y: visible;
  /* overflow-x: hidden;  */

  z-index: 100 !important;
  position:inherit;
  transition: all 0.15s linear;
  background:white !important;
}